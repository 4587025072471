/* Overlay style for the modal */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Dimming effect */
    z-index: 1000; /* Ensure the overlay is behind the content */
  }
  
  /* Content style for the modal */
  .ReactModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001; /* Slightly higher than the overlay */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
    width: 90%;
    max-width: 500px;
  }
  
  /* Header style for the modal */
  .modal-header {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  /* Input field style for the modal */
  .modal-input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  /* Button container style for the modal */
  .modal-buttons {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    gap:10px
  }
  
  /* Button style for the modal */
  .modal-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-button.cancel {
    background-color: #6c757d;
  }
  
  .modal-button:hover {
    background-color: #0056b3;
  }
  
  .modal-button.cancel:hover {
    background-color: #5a6268;
  }
  