.header-main-container {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.07);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
}
.dropdown-custom {
  background-color: transparent !important;
}
.dropdown-custom > span {
  display: flex !important;
  align-items: center !important;
}
/* .custom-dropdown-item:hover {
  background-color: red !important;
  width: 100%;
} */
