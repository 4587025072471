@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}
.custom_select__control {
  border: 1px solid #ccdae8 !important;
  border-radius: 5px !important;
  height: 40px !important;
  background-color: transparent !important;
  padding: 0px !important;
  padding-left: 5px !important;
  box-sizing: border-box !important;
  width: 200px !important;
  gap: 16px !important;
}

.custom_select__control--is-focused {
  box-shadow: none !important;
}
.custom_select__value-container {
  padding: 0 !important;
}
.custom_select__placeholder {
  color: #242424 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-wrap: nowrap;
}

.custom_select__single-value {
  color: #242424 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-wrap: nowrap;
}
.custom_select__menu {
  background: #fafafa !important;
  border-radius: 5px !important;
  border: 0.75px solid #bdbdbd !important;
  z-index: 99 !important;
  width: 200px !important;
  padding: 5px !important;
}
.custom_select__option {
  color: #4f4f4f !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-wrap: nowrap;
  cursor: pointer;
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}
.custom_select__option--is-selected {
  background-color: transparent !important;
  color: #0173b2 !important;
}
.custom_select__option--is-focused {
  background-color: transparent !important;
}
.page-title-header {
  font-size: 25px;
  color: #0173b2;
  font-weight: 600;
}
