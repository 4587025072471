.admin-select-option__input-container > input:after {
  border: none !important;
}
.dashboard-card-admin {
  width: 22%;
  height: 130px;
  background: #eff9ff;
  border-radius: 8px;
  padding: 10px 10px 0 10px;
  box-shadow: 1px 1px 1px 1px #d3e5ef;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-card-admin > h1 {
  color: #0173b2;
  font-size: 20px;
  font-weight: 600;
}
.dashboard-card-admin > .count {
  color: #242424;
  font-size: 45px;
  font-weight: 600;
  text-align: end;
}
.empty-report-text {
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  .dashboard-card-admin {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
