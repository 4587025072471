.json-editor-container {
  border: 1px solid #e3e3e3;
}

/* .json-editor-container section >div{
height: 400px;
} */
/* .view-lines.monaco-mouse-cursor-text {
    height: 400px !important;
} */

.admin-submit-btn {
  font-size: large;
  padding: 8px 25px;
  color: #fff;
  background: rgb(6 150 229);
  border-radius: 4px;
  transition: all 0.25s linear;
  font-weight: 600;
  height: 43px;
}

.admin-validate-btn {
  font-size: large;
  padding: 8px 25px;
  color: #fff;
  background: rgb(34 197 94);
  border-radius: 4px;
  transition: all 0.25s linear;
  font-weight: 600;
  height: 43px;
}

.admin-submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.admin-submit-btn:hover {
  background-color: rgb(1, 115, 178);
}
.admin-validate-btn:hover {
  background-color: rgb(16 170 73);
}
.admin-json-error-messages {
  background-color: #fee2e2;
  width: 100%;
  padding: 10px 20px;
  min-height: 50px;

}

.admin-json-error-messages ul li {
  font-size: 15px;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;

  color: #b91c1c;
}