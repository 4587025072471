@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.main-login-container {
  font-family: "Montserrat", sans-serif;
}
.login-input {
  height: 45px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
}
.input-label-text {
  color: #242424;
  font-size: 15px;
  font-weight: 500;
}
.login-input:focus {
  outline: none;
  border: 1px solid #cfcfcf;
  box-shadow: none;
}
.shadow {
  border-radius: 8px;
  box-shadow: inset 0 0 10px #cfcfcf;
}
.login-input::placeholder {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
}
.auth-error-message {
  position: absolute;
  font-size: 12px;
  color: red;
}
.auth-form-error-message {
  font-size: 12px;
  color: red;
  text-align: center;
  position: absolute;
  top: -30px;
}
