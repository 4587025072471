.admin_module_select__control {
  border: 1px solid #e3e3e3 !important;
  border-radius: 4px !important;
  height: 44px !important;
  background-color: transparent !important;
  padding: 10px !important;
  box-sizing: border-box !important;
  min-width: 220px !important;
  max-width: 220px !important;
  gap: 16px !important;
  cursor: pointer !important;
}
.admin_module_select__control--is-focused {
  box-shadow: none !important;
}
.admin_module_select__value-container {
  padding: 0 !important;
}
.admin_module_select__placeholder {
  color: var(--Black---2) !important;
  font-family: var(--font-primary-family) !important;
  font-size: var(--font-small-size) !important;
  line-height: var(--line-height-small) !important;
  font-weight: 500 !important;
  text-wrap: nowrap;
}

.admin_module_select__single-value {
  color: var(--Black---2) !important;
  font-family: var(--font-primary-family) !important;
  font-size: var(--font-small-size) !important;
  line-height: var(--line-height-small) !important;
  font-weight: 500 !important;
  text-wrap: nowrap;
}
.admin_module_select__menu {
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1px solid #e3e3e3 !important;
  z-index: 99 !important;
  min-width: 150px !important;
  max-width: 220px !important;
  padding: 5px !important;
  cursor: pointer !important;
}
.admin_module_select__option {
  color: var(--Gray---5) !important;
  font-family: var(--font-primary-family) !important;
  font-size: var(--font-small-size) !important;
  line-height: var(--line-height-small) !important;
  font-weight: 400 !important;
  text-wrap: nowrap;
  cursor: pointer;
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}
.admin_module_select__option--is-selected {
  background-color: transparent !important;
  color: var(--Blue---1) !important;
}

.admin_module_select__option--is-focused {
  background-color: transparent !important;
}

.skilltest-sortby-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}
.skilltest-sortby-dropdown > label {
  font-size: var(--font-small-size);
  color: var(--Black---2);
  font-weight: 500;
  position: absolute;
  left: 10px;
  font-family: var(--font-primary-family);
}
/* .admin-select-option > .admin_module_select__control {
    padding-left: 70px !important;
  } */
[type="date"] {
  border-color: #e3e3e3;
}
[type="date"]:focus {
  box-shadow: none !important;
  border-color: #e3e3e3;
}
.admin-report-date {
  max-width: 220px;
}

.json-view-container {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  min-height: fit-content;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
}

.json-view-container::-webkit-scrollbar {
  width: 7px;
  border-radius: 4px;
  cursor: pointer;
}

.json-view-container::-webkit-scrollbar-track {
  background-color: rgba(159, 159, 159, 0.5);
  border-radius: 4px;
}

.json-view-container::-webkit-scrollbar-thumb {
  background-color: #747474;
  border-radius: 8px;
  width: 6px;
  background-clip: content-box;
  border: 1px solid transparent;
}

.json-view-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.json-view-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.a-bts {
  font-size: large;
  font-weight: 400;
  color: #fff;
  background-color: rgb(6 150 229);
  padding: 10px 30px;
  border-radius: 4px;
  transition: all 0.3s linear;
}
.a-bts:hover {
  background-color: rgb(1, 115, 178);
}
.admin-search-btn {
  padding: 6px 20px;
  color: #fff;
  background: rgb(6 150 229);
  border-radius: 4px;
  position: absolute;
  right: 10px;
  transition: all .3s linear;
}
.admin-search-btn:hover {
  background-color: rgb(1, 115, 178);
}

.CodeMirror {
  font-size: 16px;
  color: #333;
  background-color: #f5f5f5;
}

.CodeMirror .CodeMirror-cursor {
  border-left: 1px solid #333;
}

.CodeMirror .CodeMirror-gutters {
  background-color: #e0e0e0;
  border-right: 1px solid #ccc;
}

.CodeMirror .CodeMirror-linenumber {
  color: #666;
}

.CodeMirror-scroll .CodeMirror-sizer {
  min-width: 100% !important;
  min-height: auto !important;
}
.react-codemirror2 > :nth-child(2) {
  display: none;
}


