.sidebar-container {
  background-color: rgba(239, 249, 255, 1);
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.07);
  transition: all .2s linear;
}
.sidebar-container.collapsed {
  width: 70px;
  transition: all .2s linear;
}
.sidebar-container.expanded {
  /* width: fit-content; */
  width: 160px;
  transition: all .2s linear;
}
.sidebar-logo-container {
  width: 90%;
  padding: 10px;
}
.sidebar-logo {
  height: 37px;
  transition: all 0.5s ease-in;
}
.sidebar-list-inner-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  text-decoration: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}
.sidebar-list-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

.sidebar-list-inner-container.active .sidebar-list-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  text-wrap: nowrap;
}
.sidebar-list-inner-container .sidebar-list-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
  text-wrap: nowrap;
}

.sidebar-list-inner-container:hover {
  background-color: #0173b2;
  align-items: center;
  justify-content: flex-start !important;
  width: 100%;
}
.sidebar-list-inner-container:hover .sidebar-list-text {
  color: #ffffff;
}
.sidebar-list-logo {
  width: 22px;
  height: 22px;
}

.sidebar-list-logo-inactive,
.sidebar-list-logo-active {
  width: 30px;
}

.sidebar-list-inner-container.active > .sidebar-list-logo-inactive,
.sidebar-list-inner-container:hover > .sidebar-list-logo-inactive {
  display: none;
}
.sidebar-list-logo-active {
  display: none;
}

.sidebar-list-inner-container.active > .sidebar-list-logo-active,
.sidebar-list-inner-container:hover > .sidebar-list-logo-active {
  display: block;
  width: 30px;
}

.sidebar-list-inner-container-collapsed {
  display: flex;
  flex-direction: column;
}
.collapsed-sidebar-list-text {
  display: none;
}

.sidebar-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 62px);
  overflow-y: auto;
  padding-top: 20px;
  gap: 5px;
}
.sidebar-list-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.sidebar-list-collapse-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 20px;
  text-decoration: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.sidebar-collapse-icon {
  transition: all 0.5s ease-in;
}
