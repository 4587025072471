.dashbord-main-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard-card {
  width: 22%;
  height: 130px;
  background: #eff9ff;
  border-radius: 8px;
  padding: 8px 8px 0 8px;
  box-shadow: 1px 1px 1px 1px #d3e5ef;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-card > h1 {
  color: #0173b2;
  font-size: 20px;
  font-weight: 600;
}
.dashboard-card > .count {
  color: #242424;
  font-size: 45px;
  font-weight: 600;
  text-align: end;
}
.date-input {
  border: 1px solid #ccdae8;
  border-radius: 5px;
  height: 40px;
  background-color: transparent;
  padding: 0px;
  padding-left: 5px;
  box-sizing: border-box;
  width: 200px;
}
.ag-paging-panel {
  display: flex !important;
  justify-content: space-between !important;
}
/* .ag-paging-panel > * {
  margin: 0 !important;
} */

@media screen and (max-width: 768px) {
  .dashboard-card {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
