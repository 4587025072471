/* General Flexbox Styling */
.asset-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }
  
  @media (min-width: 768px) {
    .asset-flex {
      gap: 20px;
    }
  }
  
  /* File Input styled as a Dropbox */
  #assetUpload {
    padding: 20px;
    border: 2px dashed #3498db;
    border-radius: 8px;
    background-color: #f0f8ff;
    color: #3498db;
    text-align: center;
    font-size: 16px;
    
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    outline: none;
    position: relative;

    display: flex; 
    justify-content: center; 
    align-items: center;
    overflow: hidden; 
  }

  #assetUpload::before {
    content: attr(data-placeholder);
    color: #3498db;
    font-size: 16px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  #assetUpload:hover {
    background-color: #d9eaf7;
    border-color: #2980b9;
  }
  
  #assetUpload::before {
    content: "Click here to select a file";
    display: block;
    color: #3498db;
    font-size: 16px;
    text-align: center;
  }
  
  #assetUpload::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  #assetUpload::file-selector-button {
    visibility: hidden;
  }
  
  /* Button Styling */
  .asset-btn{
    padding: 10px 20px;
    background-color: #0173b2;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .asset-btn:hover {
    background-color: #8497a4;
  }
  
  /* Link Input Field Styling */
  input[type="text"] {
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #333;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  input[type="text"]:focus {
    border-color: #3498db;
    background-color: #fff;
    outline: none;
  }
  
  /* Flexbox for link and button */
  .asset-flex-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .asset-flex-col input[type="text"] {
    flex: 1;
  }


  /* Dropzone Container */
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  transition: border 0.3s ease, background-color 0.3s ease;
}

.dropzone p {
  margin: 0;
  font-size: 16px;
  text-align: center;
}

/* File Name Display */
.dropzone .file-name {
  font-weight: bold;
  color: #4a90e2;
}

/* Dropzone Active State (File Dragged Over) */
.dropzone-active {
  border-color: #4a90e2;
  background-color: #e0f7ff;
}

/* Dropzone Hover State */
.dropzone:hover {
  border-color: #4a90e2;
}

/* Dropzone with Uploaded File */
.dropzone.has-file {
  border-color: #4caf50;
  background-color: #f0fff4;
}

/* Button */
.asset-btn {
  background-color: #0173b2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.asset-btn:hover {
  background-color: #357ab8;
}

/* Input Field */
input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Copy Button */
.asset-btn.copy {
  margin-left: 10px;
}